import { createVNode as _createVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode } from "vue";
import "core-js/modules/es.array.push.js";
import { mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage, ElMessageBox } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/message-box/style/css'

export default {
  name: "taskPool",
  components: {},
  mixins: [mixins],
  data() {
    return {
      currentRole: null,
      bindUserDialog: false,
      searchItems: [{
        label: '任务类型',
        prop: 'definitionKey',
        type: 'select',
        items: [{
          value: 'record_representation',
          label: '骑行记录申述'
        }],
        itemProp: {
          label: 'label',
          value: 'value'
        }
      }, {
        label: '用户电话',
        prop: 'customerPhone'
      }],
      topButtons: [{
        name: '批量认领',
        code: 'claim_tasks',
        type: 'primary',
        click: this.claimTask2,
        permissions: ['claim_tasks']
      }],
      columns: [{
        label: '任务类型',
        customRender: props => {
          if (props.row.processDefinitionKey === 'record_representation') {
            return _createVNode(_resolveComponent("el-tag"), null, {
              default: () => [_createTextVNode("\u9A91\u884C\u8BB0\u5F55\u7533\u8FF0")]
            });
          } else {
            return _createVNode(_resolveComponent("el-tag"), null, {
              default: () => [props.row.processDefinitionKey]
            });
          }
        }
      }, {
        label: '任务名称',
        prop: 'name'
      }, {
        label: '用户电话',
        prop: 'customerPhone'
      }, {
        label: '创建时间',
        prop: 'createTime'
      }],
      linkButtons: [{
        name: '认领',
        code: 'claim_task',
        click: this.claimTask,
        permissions: ['claim_task']
      }]
    };
  },
  methods: {
    claimTask2() {
      let taskArray = [];
      let selection = this.$refs.tablePage.selectRow;
      if (selection.length === 0) {
        ElMessage.error("请选择数据");
        return;
      }
      for (let i = 0; i < selection.length; i++) {
        let param = {
          taskId: '',
          processInstanceId: ''
        };
        param.taskId = selection[i].id, param.processInstanceId = selection[i].processInstanceId, taskArray.push(param);
      }
      ElMessageBox.confirm('确定要批量认领任务吗?', '提示', {
        type: 'warning'
      }).then(() => {
        const loading = ElLoading.service();
        this.$api.flow.flowApi.claimTask(taskArray).then(res => {
          if (res.code === 200) {
            ElMessage({
              message: res.message,
              type: 'success'
            });
            this.$refs.tablePage.fetch();
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          loading.close();
        });
      });
    },
    claimTask(row) {
      let param = {
        taskId: row.id,
        processInstanceId: row.processInstanceId
      };
      let taskArray = [];
      taskArray.push(param);
      ElMessageBox.confirm('确定要认领该任务吗?', '提示', {
        type: 'warning'
      }).then(() => {
        const loading = ElLoading.service();
        this.$api.flow.flowApi.claimTask(taskArray).then(res => {
          if (res.code === 200) {
            ElMessage({
              message: res.message,
              type: 'success'
            });
            this.$refs.tablePage.fetch();
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          loading.close();
        });
      });
    }
  }
};